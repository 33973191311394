// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@ukhomeoffice/cop-react-components';

// Local imports
import { useAxios } from '../../utils/hooks';

const TargetModeForPortAutocomplete = ({ id, fieldId, onChange, value, item, port, ...attrs }) => {
  const [modes, setModes] = useState([]);
  const [refDataResponse, setRefDataResponse] = useState();
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchModes = async () => {
      try {
        const api =
          '/refdata/v2/entities/targetMode?sort=mode.asc&mode=dataOnly&validDateTime=true&select=id,mode,modecode,crossingtype';
        const response = await axiosInstance.get(api);
        setRefDataResponse(response);
      } catch {
        setRefDataResponse(undefined);
      }
    };
    if (axiosInstance) {
      fetchModes();
    }
  }, [axiosInstance]);

  useEffect(() => {
    if (!refDataResponse) {
      return;
    }
    let validModes = refDataResponse.data?.data?.filter((mode) =>
      mode.crossingtype?.some((type) => port?.[type])
    );
    if (!validModes?.length) {
      validModes = refDataResponse.data?.data;
    }
    setModes(
      validModes?.map((mode) => {
        return {
          ...mode,
          value: mode.mode,
          label: mode.mode,
        };
      })
    );
    // Wipe old value in form data if no longer relevant assume if no crossingtype that is prepopulated and therefore we do not want it removed
    if ((!value?.crossingtype?.some((type) => port?.[type]) && value?.crossingtype) || !value) {
      onChange({ target: { name: fieldId, value: undefined } });
    }
  }, [port, refDataResponse]);

  return (
    <Autocomplete
      id={id}
      fieldId={fieldId}
      source={modes}
      value={value}
      item={item}
      onChange={onChange}
      showAllValues
      displayMenu="inline"
      returnFullData
      {...attrs}
    />
  );
};

TargetModeForPortAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    crossingtype: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    label: PropTypes.string,
    mode: PropTypes.string,
    modecode: PropTypes.string,
    value: PropTypes.string,
  }),
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
  }),
  port: PropTypes.shape({
    rail: PropTypes.bool,
    land: PropTypes.bool,
    sea: PropTypes.bool,
    air: PropTypes.bool,
  }),
};

TargetModeForPortAutocomplete.defaultProps = {
  value: null,
  item: { value: 'value', label: 'label' },
  port: null,
};

export default TargetModeForPortAutocomplete;

import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import parse from 'html-react-parser';
import { useEffect, useContext } from 'react';
import { FormPanelContext } from '../../components/form/utils/FormPanelContext';

const generateConfirmationMessage = (message, email) => {
  const { t } = useTranslation();
  const feedbackUrl = config.get('feedbackUrl');

  return message ? (
    <>
      {parse(
        Array.isArray(message)
          ? message.join('')
          : message +
              (email
                ? `<p>{t('pages.form.cop-confirmation-panel.confirmation-email-text')}</p>`
                : '')
      )}
    </>
  ) : (
    // default body
    <>
      <p className="govuk-body">{t('pages.form.cop-confirmation-panel.confirmation-message')}</p>
      <h2 className="govuk-headng-m">
        {t('pages.form.cop-confirmation-saved-panel.what-next-heading')}
      </h2>
      <p className="govuk-body">{t('pages.form.cop-confirmation-panel.what-next-body-p1')} </p>
      <p className="govuk-body">{t('pages.form.cop-confirmation-panel.what-next-body-p2')} </p>
      <p className="govuk-body">{parse(t('pages.form.cop-confirmation-panel.force-form'))}</p>
      <p className="govuk-body">
        <a className="govuk-body" href="/">
          {t('pages.form.cop-confirmation-panel.return-to-homepage')}
        </a>
      </p>
      <p className="govuk-body">
        <a
          className="govuk-body"
          href={`${feedbackUrl}?id=take_survey&type_id=e0e2125b1bdd1110f8655946464bcbf7`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('pages.form.cop-confirmation-panel.service-feedback')}
        </a>
      </p>
    </>
  );
};

const FormPageSubmission = ({ businessKey }) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const { t } = useTranslation();

  const { formPanelContext } = useContext(FormPanelContext);

  if (!formPanelContext) {
    return null;
  }

  const { confirmationEmail, confirmationMessage, confirm } = formPanelContext;

  const title = confirm?.title ?? t('pages.form.cop-confirmation-panel.title');

  const body = generateConfirmationMessage(
    confirm?.message ?? confirmationMessage,
    confirmationEmail
  );

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <div className="govuk-panel govuk-panel--confirmation">
          <h1 className="govuk-panel__title">{title}</h1>
          <div className="govuk-panel__body">
            {t('pages.form.cop-confirmation-panel.ref-no')}
            <br />
            <strong>{businessKey}</strong>
          </div>
        </div>
        {body}
      </div>
    </div>
  );
};

FormPageSubmission.propTypes = {
  businessKey: PropTypes.string.isRequired,
};

export default FormPageSubmission;

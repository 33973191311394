// Global imports.
import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

// Local imports.
import mustBeShorterThanWithNewlines from './mustBeShorterThanWithNewlines';
import mustHaveOneDriver from './mustHaveOneDriver';
import mustHaveOneHaulier from './mustHaveOneHaulier';
import validateCompositeComponent from './validateCompositeComponent';
import { CompositeTypes } from '../../Common/CompositeComponents';

const functions = {
  mustBeShorterThanWithNewlines,
  mustHaveOneDriver,
  mustHaveOneHaulier,
};

/**
 * Validate a specific component, recursively validate in the event of a container
 * @param {object} component component definition
 * @param {object} data form data for component
 * @param {array} _errors current errors on the page
 * @returns full list of errors for page component is on
 */

const validateComponent = (component, data, _errors) => {
  let errors = _errors || [];
  const path = component.full_path || component.id;
  const existingErrors = errors.filter((e) => e.id === path);
  if (existingErrors.length !== 0 && component.prioritiseRendererErrors) {
    return errors;
  }
  if (component.type === 'container') {
    component.components.forEach((c) => {
      errors = validateComponent(c, data[component.id], errors);
    });
  } else if (CompositeTypes.includes(component.type)) {
    errors = errors
      .filter((e) => e.id !== path)
      .concat(validateCompositeComponent(component, data));
  } else if (component.customValidation && Array.isArray(component.customValidation)) {
    component.customValidation.forEach((validation) => {
      const fn = functions[validation.function];
      if (typeof fn === 'function') {
        errors = errors
          .concat(fn(data[component.fieldId], validation, component, data))
          .filter((error) => error);
      }
    });
  }
  return errors;
};

/**
 * This is called as part of Form Renderer's onValidate hook. Here we
 * should do any specific validation that needs doing for COP-UI custom
 * components.
 *
 * @param {object} page The current page - complete with set-up components and formData.
 * @param {Array} currentErrors The current errors already reported in Form Renderer.
 * @returns An array of errors, made from any errors found here and errors passed in.
 */
const validatePage = (page, currentErrors) => {
  let data = page.formData;
  if (page.collection) {
    const activeIndex = Utils.CollectionPage.getActiveIndex(page.collection.name, page.formData);
    if (activeIndex !== null) {
      data = {
        ...page.formData,
        ...Utils.CollectionPage.getData(page.collection.name, page.formData)?.[activeIndex],
      };
    }
  }
  const allComponents = Utils.Component.elevateNested(page.components, data);
  let errors = Array.isArray(currentErrors) ? [...currentErrors] : [];
  if (Utils.FormPage.show(page, data) && Array.isArray(allComponents)) {
    allComponents.forEach((component) => {
      if (Utils.FormPage.show(component, data)) {
        errors = validateComponent(component, data, errors);
      }
    });
  }
  return errors;
};

export default validatePage;

import React, { useEffect, Suspense } from 'react';
import './App.scss';
import { Router, View } from 'react-navi';
import HelmetProvider from 'react-navi-helmet';
import { useTranslation } from 'react-i18next';
import config from 'react-global-configuration';
import { initAll } from 'govuk-frontend';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { KeycloakProvider, useKeycloak } from './utils/KeycloakContext';
import Layout from './components/layout';
import getBaseRoutesForUser from './routes';
import ApplicationSpinner from './components/ApplicationSpinner';
import { PermissionProvider } from './utils/PermissionContext';

import {
  useFetchTeam,
  useFetchCurrentGroup,
  useFetchGroups,
  useFetchLocation,
  useFetchUser,
} from './utils/hooks';
import { FormPanelContextProvider } from './components/form/utils/FormPanelContext';

if (window.ENVIRONMENT_CONFIG) {
  // eslint-disable-next-line no-console
  console.log('Using built version of application');
  config.set(window.ENVIRONMENT_CONFIG);
} else {
  // eslint-disable-next-line no-console
  console.log('Using non-built version of application');
  config.set({
    analyticsSiteId: process.env.REACT_APP_ANALYTICS_SITE_ID,
    analyticsUrlBase: process.env.REACT_APP_ANALYTICS_URL_BASE,
    authClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authRealm: process.env.REACT_APP_AUTH_REALM,
    authUrl: process.env.REACT_APP_AUTH_URL,
    cerberusTargetsAirpax: process.env.REACT_APP_CERBERUS_TARGETS_AIRPAX,
    cerberusTargetsRoRo: process.env.REACT_APP_CERBERUS_TARGETS_RORO,
    eab2CpPorts: process.env.REACT_APP_EAB2_CP_PORTS,
    eab2ItemPorts: process.env.REACT_APP_EAB2_ITEM_PORTS,
    eab2PortCpList: process.env.REACT_APP_EAB2_CP_PORT_LIST,
    eab2PortList: process.env.REACT_APP_EAB2_PORT_LIST,
    eab2PrePopulation: process.env.REACT_APP_EAB2_PREPOPULATION,
    eab2RoroNegativePorts: process.env.REACT_APP_EAB2_RORO_NEGATIVE_PORTS,
    eab2RoroPorts: process.env.REACT_APP_EAB2_RORO_PORTS,
    eab2SamsPorts: process.env.REACT_APP_EAB2_SAMS_PORTS,
    feedbackUrl: process.env.REACT_APP_FEEDBACK_URL,
    mandecAccessGroup: process.env.REACT_APP_MANDEC_ACCESS_GROUP,
    mandecLineManagerAccessGroup: process.env.REACT_APP_MANDEC_LM_ACCESS_GROUP,
    mandecPprtAccessGroup: process.env.REACT_APP_MANDEC_PPRT_ACCESS_GROUP,
    matomoContainerUrl: process.env.REACT_APP_MATOMO_CONTAINER_URL,
    productPageUrl: process.env.REACT_APP_PRODUCT_PAGE_URL,
    refDataPath: process.env.REACT_APP_REFDATA_PATH,
    scanAppEnabled: process.env.REACT_APP_SCAN_APP_ENABLED,
    serviceDeskUrl: process.env.REACT_APP_SERVICE_DESK_URL,
    submittedForms: process.env.REACT_APP_SUBMITTED_FORMS,
    supportUrl: process.env.REACT_APP_SUPPORT_URL,
    tmsStatusPage: process.env.REACT_APP_TMS_STATUS_PAGE,
    tmsTaskView: process.env.REACT_APP_TMS_TASK_VIEW,
    uiEnvironment: process.env.REACT_APP_UI_ENVIRONMENT,
    uiVersion: process.env.REACT_APP_UI_VERSION,
    upliftTargetSheet: process.env.REACT_APP_UPLIFT_TARGET_SHEET,
  });
}

const keycloakConfig = {
  realm: config.get('authRealm'),
  url: config.get('authUrl'),
  clientId: config.get('authClientId'),
};

const RouterView = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();

  initAll();
  useFetchTeam();
  useFetchCurrentGroup();
  useFetchGroups();
  useFetchLocation();
  useFetchUser();

  useEffect(() => {
    const _mtm = window._mtm ? window._mtm : [];
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.async = true;
    g.src = config.get('matomoContainerUrl');
    s.parentNode.insertBefore(g, s);
  }, []);

  const tracker = createInstance({
    urlBase: config.get('analyticsUrlBase'),
    siteId: config.get('analyticsSiteId'),
  });

  return initialized ? (
    <MatomoProvider value={tracker}>
      <FormPanelContextProvider>
        <Router
          hashScrollBehavior="smooth"
          routes={getBaseRoutesForUser(keycloak?.tokenParsed?.active === 'yes')}
          context={{
            t,
            isAuthenticated: keycloak.authenticated,
            active: keycloak?.tokenParsed?.active === 'yes',
            pending: keycloak?.tokenParsed?.active === 'pending',
          }}
        >
          <Layout>
            <View />
          </Layout>
        </Router>
      </FormPanelContextProvider>
    </MatomoProvider>
  ) : (
    <ApplicationSpinner translationKey="keycloak.initialising" />
  );
};
const App = () => (
  <Suspense fallback={null}>
    <HelmetProvider>
      <KeycloakProvider config={keycloakConfig}>
        <PermissionProvider>
          <RouterView />
        </PermissionProvider>
      </KeycloakProvider>
    </HelmetProvider>
  </Suspense>
);

export default App;

import SecureLocalStorageManager from './SecureLocalStorageManager';

export const cancelAmendedForm = async (axiosInstance) => {
  const allKeys = SecureLocalStorageManager.getAllKeys();
  const filteredKeys = allKeys.filter((key) => key.includes('amended'));

  filteredKeys.forEach(async (filteredKey) => {
    let data = SecureLocalStorageManager.get(filteredKey.replace('-amended', ''));

    if (!data) {
      data = SecureLocalStorageManager.get(filteredKey);
    }

    SecureLocalStorageManager.remove(filteredKey);
    SecureLocalStorageManager.remove(filteredKey.replace('-amended', ''));

    await axiosInstance.post(
      `/cop-tms-data-service/v1/dao-service/task/${data.data.businessKey}/amended/null`
    );

    const processInstances = await axiosInstance.get(
      `/camunda/engine-rest/process-instance?businessKey=${data.data.businessKey}`
    );
    processInstances?.data?.forEach(async (p) => {
      await axiosInstance.delete(`/camunda/engine-rest/process-instance/${p.id}`);
    });
  });
};

export default cancelAmendedForm;

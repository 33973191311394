// READ THIS
// This component is still in development, is untested, and is subject to any and all changes, it is not ready for production use
// Global imports
import AsyncSelect from 'react-select/async';
import { Container } from '@ukhomeoffice/cop-react-form-renderer';
import { Hint } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Local imports
import { DefaultComponents } from '../CompositeComponents';
import './MultiSelectAutocomplete.scss';
import { ReactComponent as SearchIcon } from './searchIcon.svg';
import { mapLpiData, customTheme, sortAddresses } from './AddressLookupUtils';
import { useAxios } from '../../../utils/hooks';

// Wont't search unless the user enters more characters than this
const MIN_CHARS = 2;
// Won't search untill this long passes after the last user input
const PAUSE_BETWEEN_UPDATES = 500;

const AddressLookup = ({ id, fieldId, value, label, subhint, onChange, readonly }) => {
  const [input, setInput] = useState('');
  const [timeoutTracker, setTimeoutTracker] = useState(-1);
  const axiosInstance = useAxios();

  const onSelect = (target) => {
    onChange({
      target: {
        name: fieldId,
        label,
        value: target || undefined,
        component: label,
      },
    });
  };

  const NoOptionsMessage = () => {
    let message;
    if (input.length === 0) {
      message = 'Start typing to search';
    } else if (input.length <= MIN_CHARS) {
      message = 'Continue typing to search';
    } else {
      message = 'No results found';
    }
    return <div className="search-info-text">{message}</div>;
  };

  const LoadingMessage = () => {
    return <div className="search-info-text">Loading...</div>;
  };

  const getData = async (inputValue) => {
    if (inputValue.length > MIN_CHARS) {
      const response = await axiosInstance.get(`/camunda/address?query=${inputValue}`);
      return mapLpiData(sortAddresses(response.data.results));
    }
    return null;
  };

  const promiseOptions = (inputValue) => {
    clearTimeout(timeoutTracker);
    return new Promise((resolve) => {
      const timeoutTrackerTmp = setTimeout(() => {
        resolve(getData(inputValue));
      }, PAUSE_BETWEEN_UPDATES);
      setTimeoutTracker(timeoutTrackerTmp);
    });
  };

  const onChangeText = ({ target }) => {
    onChange({
      target: {
        name: id,
        label,
        value: target.value,
        component: label,
      },
    });
  };

  return (
    <>
      <Hint>{subhint}</Hint>
      {!readonly && (
        <div className="hods-multi-select-autocomplete__outer-wrapper">
          <SearchIcon className="search-icon" />
          <div className="flex-item">
            <AsyncSelect
              loadOptions={promiseOptions}
              aria-label={label}
              id={`${id}-selectContainer`}
              inputId={id}
              instanceId={`${id}-selectComponents`}
              cacheOptions
              onChange={onSelect}
              isClearable
              theme={customTheme}
              inputValue={input}
              onInputChange={(v, action) => {
                if (action.action === 'input-change' || action.action === 'set-value') {
                  setInput(v);
                }
                return v || input;
              }}
              components={{
                DropdownIndicator: () => null,
                NoOptionsMessage,
                LoadingMessage,
              }}
              styles={{
                container: (base) => ({
                  ...base,
                  boxShadow: 'none',
                  border: '2px solid #0b0c0c',
                }),
                menu: (provided) => ({
                  ...provided,
                  border: 'none',
                  boxShadow: 'none',
                }),
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  boxShadow: 'none',
                  ':focus-within': {
                    border: '1px solid black',
                    outline: '3px solid #ffdd00 !important',
                    outlineOffset: 0,
                    boxShadow: 'inset 0 0 0 2px',
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  height: '100%',
                  color: '#0b0c0c',
                  fontSize: 18,
                }),
                clearIndicator: (provided) => ({
                  ...provided,
                  color: '#0b0c0c',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isFocused ? 'white' : 'black',
                }),
              }}
            />
          </div>
        </div>
      )}
      <Container
        container={{ id, fieldId, components: DefaultComponents.ukAddress, readonly }}
        onChange={onChangeText}
        onTopLevelChange={onChangeText}
        value={value}
        formData={value}
      />
    </>
  );
};

AddressLookup.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subhint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    lineOne: PropTypes.string,
    lineTwo: PropTypes.string,
    townOrCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
};

AddressLookup.defaultProps = {
  value: null,
  readonly: false,
  subhint: undefined,
};

export default AddressLookup;

import adjustPayloadForType from './adjustPayloadForType';
import getSubmissionFunction from './getSubmissionFunction';
import getSubmissionPayload from './getSubmissionPayload';
import getSubmissionUrl from './getSubmissionUrl';
import uploadDocuments from './uploadDocuments';
import runCustomComponentValidation from '../../components/form/validate';
import Logger from '../logger';

const onRequest = (req, token) => {
  const headers = { ...req.headers, Authorization: `Bearer ${token}` };
  return { ...req, headers };
};

const onSubmit = async (
  type,
  form,
  payload,
  submittedByEmail,
  submittedByName,
  submissionTeam,
  axiosInstance,
  processKey,
  fileServiceClient = null,
  attachmentServiceURL = null
) => {
  const submissionPayload = await getSubmissionPayload(
    form,
    payload,
    submittedByEmail,
    submittedByName,
    axiosInstance,
    submissionTeam
  );
  const axiosFn = getSubmissionFunction(type, submissionPayload, axiosInstance);
  const submissionUrl = await getSubmissionUrl(
    type,
    submissionPayload,
    form.name,
    axiosInstance,
    processKey
  );
  // Upload any files to the attachment service.
  // This has to be done before adjustPayloadForType is
  // called as that wipes out any meta.document data on
  // the submission payload.
  if (fileServiceClient && attachmentServiceURL) {
    await uploadDocuments(fileServiceClient, attachmentServiceURL, submissionPayload);
  }
  const adjustedPayload = adjustPayloadForType(type, form.name, submissionPayload);
  const { data } = await axiosFn(submissionUrl, adjustedPayload);
  return {
    data,
    businessKey: submissionPayload.businessKey,
    submissionStartDate: submissionPayload.form.submissionStartDate,
  };
};

const onSubmitToEpms = async (
  type,
  form,
  payload,
  submittedByEmail,
  submittedByName,
  axiosInstance,
  keycloak
) => {
  const submissionUrl = await getSubmissionUrl('start', {}, 'send-seizure-details-to-epms', {});
  const payloadCopy = JSON.parse(JSON.stringify(payload));
  let submissionPayload = await getSubmissionPayload(
    form,
    payloadCopy,
    submittedByEmail,
    submittedByName,
    axiosInstance
  );
  submissionPayload.submittingUsersEmail = submittedByEmail;
  submissionPayload.submittingUsersName = submittedByName;
  try {
    submissionPayload = adjustPayloadForType(
      'convertForEpms',
      'recordBorderEvent',
      submissionPayload
    );
  } catch (e) {
    Logger.error({
      token: keycloak.token,
      message: 'Failed to convert payload for ePMS',
      componentStack: e.message,
    });
  }
  await axiosInstance.post(submissionUrl, submissionPayload);
};

const onTransitionToEabOne = async (
  form,
  payload,
  submittedByEmail,
  submittedByName,
  navigation,
  axiosInstance
) => {
  // Send user to EAB1
  navigation.navigate('/forms/collect-event-at-border');
  // Submit EAB2 task so the process can be terminated, if no id set then no process exists yet
  if (payload.id) {
    const submitUrl = await getSubmissionUrl('submit', payload, {}, axiosInstance);
    let submissionPayload = await getSubmissionPayload(
      form,
      payload,
      submittedByEmail,
      submittedByName,
      axiosInstance
    );
    submissionPayload = adjustPayloadForType('submit', 'cop-addABorderEvent', submissionPayload);
    await axiosInstance.post(submitUrl, submissionPayload);
  }
};

/**
 * Run custom Validators on a form
 * @param form Form configuration
 * @param payload Form data
 * @param axiosInstance
 * @param actionType The type of action that triggered the validation.
 * @returns {Promise<Awaited<unknown>[]>}
 */

const customValidate = (form, payload, axiosInstance, pageId, actionType = null) => {
  // filter form components only want those with custom validation
  const validate = form.components.filter((component) =>
    Object.prototype.hasOwnProperty.call(component, 'customValidation')
  );
  const tests = []; // array of validation promises
  if (validate) {
    validate.forEach((component) => {
      // check form contents for field
      const componentIdentifier = component.customValidationValueProxy || component.fieldId;
      if (Object.prototype.hasOwnProperty.call(payload, componentIdentifier)) {
        // run through validations for this field
        tests.push(
          ...runCustomComponentValidation(
            payload[componentIdentifier],
            component,
            axiosInstance,
            payload,
            pageId,
            actionType
          )
        );
      }
      // check if form has items...
      if (payload.items) {
        // check each item
        payload.items.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(item, component.fieldId)) {
            // validate the item
            tests.push(
              ...runCustomComponentValidation(
                item[component.fieldId],
                component,
                axiosInstance,
                payload,
                pageId
              )
            );
          }
        });
      }
    });
  }

  const promise = Promise.all(tests);

  // no need to check for errors if no tests
  return promise.then((results) => {
    // transform errors into form errors
    const errors = [];
    results.flat().forEach((result) => {
      if (result) {
        errors.push({
          ...result,
          error: result.message || `${result.component.label}: Unknown Error`,
          id: result.component.id,
        });
      }
    });
    if (errors.length) {
      throw Object.assign(new Error(), {
        errors,
        wipeData: false,
      });
    }
    // resolve promise
    return true;
  });
};

const formHooks = {
  onRequest,
  onSubmit,
  onSubmitToEpms,
  onTransitionToEabOne,
  customValidate,
};

export default formHooks;

import { Utils } from '@ukhomeoffice/cop-react-components';
import { Utils as FrUtils } from '@ukhomeoffice/cop-react-form-renderer';

/**
 * Extracts the data for the current active entry in a collection and puts it
 * at the top level of the form data
 * @param {*} page current page in form
 * @param {*} formData all form data
 * @returns formData with active collection item at top level
 */
const supplementFormDataFromCollection = (page, formData) => {
  const collectionName = page.collection?.name;

  const dataFromPage = formData?.fullPages?.find((p) => p.id === page.id)?.formData;
  const formDataSupplemented = { ...formData, ...dataFromPage };

  const activeIndex = FrUtils.CollectionPage.getActiveIndex(collectionName, formDataSupplemented);
  const active = formDataSupplemented?.[collectionName]?.[activeIndex];
  return { ...formDataSupplemented, ...active };
};

/**
 * Gets Title from page, if page contains a array of options then pick the correct one base on form data
 * @param {string | array} pageTitle Title may be a string or a list of options
 * @param {object} formData Current form data.
 * @returns A string containing the formatted page title.
 */
const getPageTitle = (title, formData) => {
  if (!(title instanceof Array)) {
    return title;
  }
  const p = title.filter((t) => FrUtils.Condition.meetsAll(t.show_when, formData))[0];
  if (p instanceof Object) {
    return p.title;
  }
  return '';
};

/**
 * Gets the document title with the correct page prefix.
 * @param {string} coreDocTitle The coreDocTitle.
 * @param {string} pageId The ID of the page to look up.
 * @param {array} pages The list of pages for look up.
 * @param {object} formData Current form data.
 * @returns A string containing the formatted document title.
 */
const getDocumentTitle = (coreDocTitle, pageId, pages, formData) => {
  if (pageId === 'hub') {
    return coreDocTitle;
  }

  let pageTitle = 'Check your answers - '; // default
  const page = pages.find((x) => x.id === pageId);
  if (page) {
    // find a component with a page heading
    const component = page.components?.find((c) => c.isPageHeading || c.fieldset?.isPageHeading);
    const title = component?.label || page.tabName || getPageTitle(page.title, formData); // title for page
    pageTitle = title ? `${title} - ` : '';
  }

  const formDataSupplemented = page?.collection
    ? supplementFormDataFromCollection(page, formData)
    : formData;
  const pageTitleInterpolated = Utils.interpolateString(pageTitle, formDataSupplemented);
  return `${pageTitleInterpolated}${coreDocTitle}`;
};

export default getDocumentTitle;
